import { POPOUT_PLAYER_TARGET } from "shared/constants";

export interface UniversalPlayerState {
  playerOpen: boolean;
  addedClip: null | Record<string, unknown>;
  universalPlayerRef: HTMLVideoElement | null;
  playerClip: Record<string, any>;
  playerInitialClip: null | Record<string, unknown>;
  playerList: Record<string, unknown>[];
  playerIsPlaying: boolean;
  playerPopoutRef: HTMLElement | null;
  playerPopoutTarget: typeof POPOUT_PLAYER_TARGET;
  playerIsBeingScrubbed: boolean;
  popoutVideoRef: HTMLVideoElement | null;
  activeControl: string | null;
  playerIsDismissed: boolean;
  playerIsExpanded: boolean;
}

export default (): UniversalPlayerState => ({
  playerOpen: false,
  addedClip: null,
  universalPlayerRef: null,
  playerClip: {},
  playerInitialClip: null,
  playerList: [],
  playerIsPlaying: false,
  playerPopoutRef: null,
  playerPopoutTarget: POPOUT_PLAYER_TARGET,
  playerIsBeingScrubbed: false,
  popoutVideoRef: null,
  activeControl: null,
  playerIsDismissed: false,
  playerIsExpanded: false,
});
