import { useSideBarStore } from "desktop/src/stores/sideBar";
import LogRocket from "logrocket";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import {
  newAnalyticsProxy,
  reset,
  track,
  updateIntercom,
} from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { appInstance } from "shared/boot/app";
import { setLocale } from "shared/boot/i18n";
import {
  cachedToken,
  cacheOrganisationToken,
  clearCachedTokens,
  updateUserToken,
} from "shared/helpers/authorization";
import {
  getInitialCurrentUserState,
  getInitialOrganisationState,
} from "shared/helpers/user";
import { OrganisationAggregationMultiplier } from "shared/resources";
import Permissions from "shared/services/Permissions";
import StorageService from "shared/services/StorageService";
import type { Nullable } from "shared/types";

import { useUniversalPlayerStore } from "../universalPlayer";

import type {
  AccountManager,
  AuthToken,
  CurrentStreamsLayout,
  CurrentUser,
  Organisation,
  OrganisationTeam,
  Preferences,
  TeamMember,
  UpdateUser,
} from "./types";

const handleErrors = true;

export const useUserStore = defineStore("user", () => {
  const accountManager = ref<AccountManager>();

  const betaEnabled = ref<boolean>(
    JSON.parse(sessionStorage.getItem("betaEnabled") || "false")
  );

  const enforceTwoFactor = ref(false);
  const isLoggedIn = ref<boolean>(false);
  const isAdminUser = ref<boolean>(false);
  const loadError = ref<Nullable<unknown>>(null);
  const pending = ref<boolean>(false);
  const preferences = ref<Preferences>({});
  const token = ref<AuthToken>("");
  const identityProvider = ref<Nullable<string>>("");
  const currentUser = ref<CurrentUser>(getInitialCurrentUserState());
  const currentStreamsLayout = ref<CurrentStreamsLayout>("stream");
  const showFullExcerpts = ref<boolean>(false);
  const customerViewEnabled = ref<boolean>(false);
  const organisation = ref<Organisation>(getInitialOrganisationState());
  const multiplier = ref<Nullable<OrganisationAggregationMultiplier>>(null);
  const team = ref<TeamMember[]>([]);

  const hasAdvancedAccess = computed((): boolean => {
    const permissions = new Permissions();

    return Boolean(
      permissions.has("has_advanced_access") && !customerViewEnabled.value
    );
  });

  const adminUserEnabled = computed((): boolean =>
    Boolean(isAdminUser.value && !customerViewEnabled.value)
  );

  const organisationId = computed(
    (): number | undefined => currentUser.value.organisation?.id
  );

  const role = computed((): string => currentUser.value.role || "");

  const primaryOrganisationTeam = computed((): OrganisationTeam | undefined =>
    currentUser.value.organisation_teams.find(
      (organisationTeam) => organisationTeam.primary
    )
  );

  const sortedUserOrganisationTeams = computed((): OrganisationTeam[] => {
    const primaryOrganisationTeams: OrganisationTeam[] =
      primaryOrganisationTeam.value ? [primaryOrganisationTeam.value] : [];

    const sortedSecondaryTeams: OrganisationTeam[] | undefined =
      currentUser.value.organisation_teams
        .filter((organisationTeam) => !organisationTeam.primary)
        .sort((secondaryTeamA, secondaryTeamB) =>
          secondaryTeamA.name > secondaryTeamB.name ? 1 : -1
        );

    if (sortedSecondaryTeams) {
      return primaryOrganisationTeams.concat(sortedSecondaryTeams);
    }

    return primaryOrganisationTeams;
  });

  const sortedTeam = computed((): TeamMember[] =>
    team.value
      .slice()
      .sort((userA, userB) => (userA.first_name > userB.first_name ? 1 : -1))
  );

  const sortedAllOrganisationTeams = computed((): OrganisationTeam[] => {
    if (!organisation.value || !organisation.value.organisation_teams) {
      return [];
    }

    return organisation.value.organisation_teams.toSorted((teamA, teamB) =>
      teamA.name > teamB.name ? 1 : -1
    );
  });

  const sortedOrganisations = computed(() => [
    {
      ...currentUser.value.organisation,
      teams: currentUser.value.organisation_teams?.length
        ? currentUser.value.organisation_teams
        : null,
    },
    ...(currentUser.value.other_organisations || []),
  ]);

  const hasLaunchpadBetaEnabled = computed<boolean>(
    () => currentUser.value.launchpad_beta
  );

  function loginSuccess(newToken: AuthToken): void {
    token.value = newToken;
    isLoggedIn.value = true;
    pending.value = false;
  }

  function setEnforceTwoFactor(updateEnforceTwoFactor: boolean): void {
    enforceTwoFactor.value = isAdminUser.value ? false : updateEnforceTwoFactor;
  }

  function setCurrentStreamsLayout(newView: CurrentStreamsLayout): void {
    currentStreamsLayout.value = newView;
  }

  function login({
    emailOrPhone,
    pin,
    platform,
    twoFactorCode,
  }: {
    emailOrPhone: string;
    pin: string;
    platform?: string;
    twoFactorCode?: string;
  }): Promise<void> {
    pending.value = true;

    return $streemApiV1
      .post("sessions", {
        params: {
          email_or_phone: emailOrPhone,
          password: pin,
          platform,
          two_factor_code: twoFactorCode,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const result = response.data;
        const { auth_token: authToken } = result;

        useUserStore().loginSuccess(authToken);

        useUserStore().setEnforceTwoFactor(Boolean(result.enforce_two_factor));
        identityProvider.value = "streem";

        StorageService.set("login", emailOrPhone);
        StorageService.set("identityProvider", "streem");

        await clearCachedTokens();

        return updateUserToken(authToken);
      })
      .catch((error) => {
        pending.value = false;

        return Promise.reject(error);
      });
  }

  function initialState(): void {
    accountManager.value = undefined;
    betaEnabled.value = false;
    currentUser.value = getInitialCurrentUserState();
    currentStreamsLayout.value = "stream";
    customerViewEnabled.value = false;
    enforceTwoFactor.value = false;
    identityProvider.value = "";
    isAdminUser.value = false;
    isLoggedIn.value = false;
    loadError.value = null;
    multiplier.value = null;
    organisation.value = getInitialOrganisationState();
    pending.value = false;
    preferences.value = {};
    showFullExcerpts.value = false;
    team.value = [];
    token.value = "";
  }

  async function logout(clearAll = false): Promise<void> {
    if (!isAdminUser.value) {
      track("Signed Out");
      reset();
    }

    sessionStorage.clear();

    await Promise.all([
      StorageService.remove("identityProvider"),
      clearCachedTokens(),
    ]);

    useUniversalPlayerStore().closeAndStop();
    useSideBarStore().hideSideBar();

    if (clearAll) {
      await StorageService.remove("login");
    }

    initialState();
    useUserStore().setEnforceTwoFactor(false);

    identityProvider.value = null;

    updateIntercom({
      hide_default_launcher: false,
      alignment: "right",
    });
  }

  async function switchOrganisation(
    switchOrganisationId: number,
    platform: string
  ): Promise<void> {
    let cachedAuthToken = await cachedToken(switchOrganisationId);

    if (!cachedAuthToken) {
      const { data } = await $streemApiV1.post("sessions/switch", {
        params: {
          id: switchOrganisationId,
          platform,
        },
      });

      cachedAuthToken = data.auth_token;

      await cacheOrganisationToken(cachedAuthToken, switchOrganisationId);
    }

    await Promise.all([
      StorageService.remove("organisationId", sessionStorage),
      StorageService.set("newOrgId", switchOrganisationId, sessionStorage),
    ]);

    window.location.replace("/");
  }

  function authenticated() {
    loadError.value = null;
  }

  function setUserLocale(user: CurrentUser) {
    setLocale(user.locale);
  }

  async function getUser(): Promise<void> {
    const response = await $streemApiV1.get("user");

    const user = response.data;

    currentUser.value = user;
    preferences.value = user.preferences;

    const authToken = await StorageService.get("token");
    updateUserToken(authToken, user.organisation.id);

    useUserStore().setEnforceTwoFactor(user.enforce_two_factor);
    useUserStore().setUserLocale(user);

    useUserStore().authenticated();
  }

  function adminUserOn(updateAccountManager: AccountManager): void {
    isAdminUser.value = true;

    if (updateAccountManager) {
      accountManager.value = updateAccountManager;
    }
  }

  function adminUserOff(): void {
    isAdminUser.value = false;
  }

  async function adminUserLogin({
    token: newToken,
    accountManager: newAccountManager,
  }: {
    token: string;
    accountManager: AccountManager;
  }): Promise<void> {
    await StorageService.set(
      "accountManager",
      JSON.stringify(newAccountManager),
      sessionStorage
    );

    await updateUserToken(newToken);

    useUserStore().adminUserOn(newAccountManager);

    await useUserStore().getUser();

    const permissions = new Permissions();

    if (permissions.has("has_advanced_access")) {
      await newAnalyticsProxy(appInstance, { isAdminUser: true });

      if (LogRocket) {
        LogRocket.identify(String(currentUser.value.id), {
          accountManager: newAccountManager.name,
        });
      }
    } else {
      useUserStore().adminUserOff();
    }

    useUserStore().loginSuccess(newToken);
  }

  function loginFailure(): void {
    pending.value = false;
  }

  function unauthorized(error: unknown): void {
    loadError.value = error;
  }

  async function sudo({
    pin,
    permission,
    idToken,
    provider = "streem",
  }: {
    pin: string;
    permission: string;
    idToken?: string;
    provider?: string;
  }): Promise<boolean> {
    track("Sudo");

    const response = await $streemApiV1.post("sessions/sudo", {
      params: {
        pin,
        permission,
        provider,
        id_token: idToken,
      },
    });

    const result = response.data;
    const authToken = result.auth_token;

    if (authToken) {
      useUserStore().loginSuccess(authToken);

      await updateUserToken(authToken);

      return true;
    }

    return false;
  }

  async function getOrganisation(): Promise<void> {
    const response = await $streemApiV1.get(
      `organisations/${currentUser.value.organisation?.id}`,
      {
        handleErrors,
      }
    );

    organisation.value = response.data;
  }

  async function getMultiplier(): Promise<void> {
    const response = await OrganisationAggregationMultiplier.includes([
      "aveMultiplierLastUpdatedBy",
      "audienceMultiplierLastUpdatedBy",
    ])
      .where({ organisationId: currentUser.value.organisation?.id })
      .all();

    if (response.data.length) {
      [multiplier.value] = response.data;
    }
  }

  async function getTeam(): Promise<void> {
    const response = await $streemApiV1.get("user/team", { handleErrors });

    team.value = response.data;
  }

  async function updateUser(data: UpdateUser): Promise<void> {
    const response = await $streemApiV1.put("user", {
      params: { user: data },
    });

    currentUser.value = response.data;
    useUserStore().setUserLocale(response.data);
    useUserStore().setEnforceTwoFactor(response.data.enforce_two_factor);
  }

  async function updateUserPreferences({
    id,
    attribute,
    value,
  }: {
    id: number;
    attribute: keyof Preferences;
    value: unknown;
  }): Promise<void> {
    preferences.value[attribute] = value;

    await $streemApiV1.put(`users/${id}/update_preferences`, {
      params: { attribute, value },
      handleErrors,
    });
  }

  function toggleShowExcerpts(): void {
    showFullExcerpts.value = !showFullExcerpts.value;
  }

  function toggleCustomerView(): void {
    customerViewEnabled.value = !customerViewEnabled.value;
  }

  function toggleBetaFeatures(): void {
    betaEnabled.value = !betaEnabled.value;
  }

  function microsoftAuthCallback(
    idToken: string,
    platform: string
  ): Promise<void> {
    return $streemApiV1
      .post("sessions", {
        params: {
          provider: "microsoft",
          id_token: idToken,
          platform,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const authToken: AuthToken = {
          token: response.data.auth_token,
          userId: response.data.user_id,
        };

        StorageService.set("identityProvider", "microsoft");
        await updateUserToken(authToken.token);

        useUserStore().loginSuccess(authToken);
        identityProvider.value = "microsoft";
      })
      .catch((error) => {
        loginFailure();

        throw error;
      });
  }

  function googleAuthCallback({
    idToken,
    platform,
    client,
  }: {
    idToken: string;
    platform: string;
    client: string;
  }): Promise<void> {
    return $streemApiV1
      .post("sessions", {
        params: {
          provider: "google",
          id_token: idToken,
          platform,
          client,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const authToken: AuthToken = {
          token: response.data.auth_token,
          userId: response.data.user_id,
        };

        StorageService.set("identityProvider", "google");
        await updateUserToken(authToken.token);

        useUserStore().loginSuccess(authToken);
        identityProvider.value = "google";
      })
      .catch((error) => {
        useUserStore().loginFailure();

        throw error;
      });
  }

  async function refreshGoogleSsoCallback({
    idToken,
    platform,
    client,
  }: {
    idToken: string;
    platform: string;
    client: string;
  }): Promise<void> {
    const response = await $streemApiV1.post("sessions", {
      params: {
        provider: "google",
        refresh: true,
        id_token: idToken,
        platform,
        client,
        resume: true,
      },
      handleErrors: false,
    });

    const authToken: AuthToken = {
      token: response.data.auth_token,
      userId: response.data.user_id,
    };

    await updateUserToken(authToken.token);

    identityProvider.value = "google";
    useUserStore().loginSuccess(authToken);
  }

  async function refreshMicrosoftSsoCallback(
    idToken: string,
    platform: string
  ): Promise<void> {
    const response = await $streemApiV1.post("sessions", {
      params: {
        provider: "microsoft",
        refresh: true,
        id_token: idToken,
        platform,
        resume: true,
      },
      handleErrors: false,
    });

    const authToken: AuthToken = {
      token: response.data.auth_token,
      userId: response.data.user_id,
    };

    await updateUserToken(authToken.token);

    identityProvider.value = "microsoft";
    useUserStore().loginSuccess(authToken);
  }

  function setOrganisation(newOrganisation: Organisation): void {
    organisation.value = newOrganisation;
  }

  function isSuperRole(currentRouteProductMenu: string): boolean {
    if (currentRouteProductMenu === "social") {
      return currentUser.value.social_role === "super";
    }

    if (currentRouteProductMenu === "outreach") {
      return currentUser.value.outreach_role === "super";
    }

    return currentUser.value.role === "super";
  }

  return {
    accountManager,
    betaEnabled,
    currentStreamsLayout,
    currentUser,
    customerViewEnabled,
    enforceTwoFactor,
    identityProvider,
    isAdminUser,
    isLoggedIn,
    loadError,
    multiplier,
    organisation,
    pending,
    preferences,
    showFullExcerpts,
    team,
    token,

    hasAdvancedAccess,
    hasLaunchpadBetaEnabled,
    adminUserEnabled,
    organisationId,
    primaryOrganisationTeam,
    role,
    sortedAllOrganisationTeams,
    sortedOrganisations,
    sortedTeam,
    sortedUserOrganisationTeams,

    adminUserLogin,
    adminUserOff,
    adminUserOn,
    authenticated,
    getMultiplier,
    getOrganisation,
    getTeam,
    getUser,
    googleAuthCallback,
    isSuperRole,
    login,
    loginFailure,
    loginSuccess,
    logout,
    microsoftAuthCallback,
    refreshGoogleSsoCallback,
    refreshMicrosoftSsoCallback,
    setCurrentStreamsLayout,
    setEnforceTwoFactor,
    setOrganisation,
    setUserLocale,
    sudo,
    switchOrganisation,
    toggleBetaFeatures,
    toggleCustomerView,
    toggleShowExcerpts,
    unauthorized,
    updateUser,
    updateUserPreferences,
  };
});

export default useUserStore;
