<template>
  <div class="modals-container">
    <TransitionGroup name="modal">
      <Component
        :is="modalOptions.component"
        v-for="(modalOptions, index) in modals"
        :key="index"
        v-bind="modalOptions.props"
        :style="{ zIndex: 3000 + index }"
        v-on="modalOptions.events"
      />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from "vue";
import { ref } from "vue";

import type { ModalContainerOptions } from "shared/modals/ModalService";

const modals = ref<ModalContainerOptions<ComponentPublicInstance>[]>([]);

function openModal(
  modalOptions: ModalContainerOptions<ComponentPublicInstance>
) {
  modals.value.push(modalOptions);
}

function closeModal(modalName: string) {
  const index = modals.value.findIndex(({ name }) => name === modalName);

  if (index > -1) {
    modals.value.splice(index, 1);
  }
}

function closeAllModals() {
  modals.value = [];
}

defineExpose({
  modals,
  openModal,
  closeModal,
  closeAllModals,
});
</script>

<style lang="scss" scoped>
.modals-container {
  position: fixed;
  z-index: 8000;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from :deep(.streem-modal),
.modal-leave-to :deep(.streem-modal) {
  transform: scale(1.1);
}
</style>
