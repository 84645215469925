export default {
  playerHide() {
    this.playerOpen = false;
  },
  playerShow() {
    this.playerOpen = true;
  },
  playerSetInitialClip(clip) {
    this.playerClip = clip;
    this.playerInitialClip = clip;
  },
  playerUpdateClip(clip) {
    this.playerClip = clip;
  },
  playerSetRef(ref) {
    this.universalPlayerRef = ref;
  },
  setPlayerIsBeingScrubbed(isScrubbing) {
    this.playerIsBeingScrubbed = isScrubbing;
  },
  setPlayerPopoutRef(ref) {
    this.playerPopoutRef = ref;
  },
  setPopoutVideoRef(ref) {
    this.popoutVideoRef = ref;
  },
  playerInsertClip(clip) {
    if (this.playerList.every((listItem) => listItem.id !== clip.id)) {
      const listIndex = this.playerList.findIndex(
        (listItem) => listItem.id === this.playerClip.id
      );

      this.playerList.splice(listIndex + 1, 0, clip);
      this.addedClip = clip;
    }
  },
  playerAppendClip(clip) {
    if (this.playerList.every((listItem) => listItem.id !== clip.id)) {
      this.playerList.push(clip);
      this.addedClip = clip;
    }
  },
  playerClearList() {
    this.playerList = [];
  },
  playerRemoveListItem(clip) {
    this.playerList = this.playerList.filter(
      (listItem) => listItem.id !== clip.id
    );
  },
  setPlayerPlaying(playing) {
    this.playerIsPlaying = playing;
  },
  setPlayerPopoutTarget(target) {
    this.playerPopoutTarget = target;
  },
  setPlayerPopoutActiveControl(control) {
    this.activeControl = control;
  },
  setPlayerDismissed(dismissed) {
    this.playerIsDismissed = dismissed;
  },
  setPlayerExpanded(expanded) {
    this.playerIsExpanded = expanded;
  },
  closeAndStop() {
    this.playerHide();

    if (this.universalPlayerRef) {
      this.universalPlayerRef.pause();
    }
  },
};
