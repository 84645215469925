import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import AnalyticsDataStream from "./AnalyticsDataStream";
import Organisation from "./Organisation";

@Model()
class AnalyticsIntegration extends ApplicationRecord {
  static jsonapiType = "analytics_integrations";

  @BelongsTo() organisation: Organisation;

  @HasMany() analyticsDataStreams: AnalyticsDataStream[];

  @Attr() analyticsProperty: string;

  @Attr() serviceAccount: string;

  @Attr() organisationId: number;

  @Attr() isIntegrated: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default AnalyticsIntegration;
