import type { Sentiment } from "shared/types/mentions";

export interface SentimentOption {
  value: number;
  range: [number, number];
  label: string;
  field: string;
  color: string;
  icon?: string;
  newIcon?: string;
  inRange: (sentiment: Sentiment) => boolean;
}

function inRange(this: SentimentOption, sentiment: Sentiment): boolean {
  if (sentiment === null) return false;

  const roundedSentiment = Math.round(Number(sentiment) * 100) / 100;

  return this.range[0] <= roundedSentiment && this.range[1] >= roundedSentiment;
}

const sentimentOptions: SentimentOption[] = [
  {
    value: 1,
    range: [0.61, 1],
    label: "Positive",
    field: "positive",
    color: "#49d067",
    icon: "positive",
    inRange,
  },
  {
    value: 0.5,
    range: [0.21, 0.6],
    label: "Trending Positive",
    field: "trending_positive",
    color: "#78a6dd",
    icon: "trending-positive",
    inRange,
  },
  {
    value: 0,
    range: [-0.19, 0.2],
    label: "Balanced",
    field: "balanced",
    color: "#3e475e",
    icon: "balanced",
    inRange,
  },
  {
    value: -0.5,
    range: [-0.59, -0.2],
    label: "Trending Negative",
    field: "trending_negative",
    color: "#f98d5d",
    icon: "trending-negative",
    inRange,
  },
  {
    value: -1,
    range: [-1, -0.6],
    label: "Negative",
    field: "negative",
    color: "#f95858",
    icon: "negative",
    inRange,
  },
];

export default sentimentOptions;
