import { PublicClientApplication } from "@azure/msal-browser";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";

import { ErrorDialog } from "shared/boot/alert";
import { $streemApiAdmin, $streemApiV1 } from "shared/boot/api";
import { Organisation } from "shared/resources";
import StorageService from "shared/services/StorageService";
import { useUserStore as useSharedUserStore } from "shared/stores/user";
import {
  ACCESS_LEVELS,
  type AccessLevel,
  type AccessLevelsValues,
} from "shared/stores/user/types";
import type { Nullable } from "shared/types";

declare global {
  interface GoogleCredentialResponse {
    credential: string;
    select_by: string;
    clientId: string;
  }

  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            callback: (response: GoogleCredentialResponse) => void;
            auto_select?: boolean;
          }) => void;
        };
      };
    };
  }
}

const ADMIN_ROLES = {
  limited: "Limited",
  sales: "Sales",
  normal: "Normal",
  overnight_admin: "Overnight Admin",
  media_analyst: "Media Analyst",
  external_media_analyst: "External Media Analyst",
  external_der_analyst: "External DER Analyst",
  content_admin: "Content Admin",
  super: "Super",
  copyright: "Copyright",
} as const;

const handleErrors = true;

export const useUserStore = defineStore("adminUser", () => {
  const sharedUserStore = useSharedUserStore();
  const { logout: userLogout, loginSuccess, loginFailure } = sharedUserStore;
  const { currentUser } = storeToRefs(sharedUserStore);

  const loginFailed = ref<boolean>(false);
  const loadError = ref<Nullable<unknown>>(null);
  const accessLevels = ref(ACCESS_LEVELS);
  const adminRoles = ref(ADMIN_ROLES);
  const organisation = ref<Organisation>();

  function hasAccessLevel(level: AccessLevel): boolean {
    const currentLevel = currentUser.value.access_level;

    return (ACCESS_LEVELS[level] as ReadonlyArray<AccessLevelsValues>).includes(
      currentLevel
    );
  }

  const hasSuperAccess = computed(() => hasAccessLevel("super"));

  const hasNormalAccess = computed(() => hasAccessLevel("normal"));

  const hasOrganisationEditAccess = computed(() =>
    hasAccessLevel("organisation_edit")
  );

  const hasOrganisationReadAccess = computed(() =>
    hasAccessLevel("organisation_read")
  );

  const hasLimitedAccess = computed(() => hasAccessLevel("limited"));

  const hasRosterAccess = computed(() => hasAccessLevel("overnight_admin"));

  const hasMediaAnalystAccess = computed(() => hasAccessLevel("media_analyst"));

  const hasDERAccess = computed(() => hasAccessLevel("der"));

  const hasCopyrightAccess = computed(() =>
    hasAccessLevel("copyright_reporting")
  );

  const isInsightsAMTeam = computed(() => {
    const operationalTeam = currentUser.value.operational_team;

    if (!operationalTeam) return false;

    return operationalTeam.name.toLowerCase().includes("insights");
  });

  const canManageOrganisation = computed(() => {
    if (!hasOrganisationEditAccess.value) return false;

    if (hasSuperAccess.value || organisation.value?.status === "draft") {
      return true;
    }

    const allowedUserIds =
      organisation.value?.organisationsManagers.map(({ managerId }) =>
        Number(managerId)
      ) || [];

    const orgTeamId = organisation.value?.operationalTeamId;
    const operationalTeam = currentUser.value.operational_team;
    if (!operationalTeam) return false;

    if (orgTeamId && Number(orgTeamId) === operationalTeam.id) {
      return true;
    }

    return allowedUserIds.includes(currentUser.value.id);
  });

  function googleAuthCallback(): (response: GoogleCredentialResponse) => void {
    return ({ credential }: { credential: string }) => {
      $streemApiV1
        .post("sessions", {
          params: {
            provider: "google",
            id_token: credential,
            platform: "admin",
          },
          handleErrors: false,
        })
        .then(async (response) => {
          const token = response.data.auth_token;

          await StorageService.set("token", token);

          loginSuccess({ token, userId: response.data.user_id });
        })
        .catch(() => {
          loginFailure();
        });
    };
  }

  function microsoftAuthCallback(): ({ idToken }: { idToken: string }) => void {
    return ({ idToken }: { idToken: string }) => {
      $streemApiV1
        .post("sessions", {
          params: {
            provider: "microsoft",
            id_token: idToken,
            platform: "admin",
          },
          handleErrors: false,
        })
        .then(async (response) => {
          const token = response.data.auth_token;

          await StorageService.set("token", token);

          loginSuccess({ token, userId: response.data.user_id });
        })
        .catch(() => {
          loginFailure();
        });
    };
  }

  function initGoogleAuth(): void {
    const { google } = window;

    google.accounts.id.initialize({
      client_id:
        "591696447202-cnffv3kb0q2ufmnmno8inhp1e380h3nj.apps.googleusercontent.com",
      callback: googleAuthCallback(),
      auto_select: true,
    });
  }

  function initMicrosoftAuth(clientId: string) {
    const config = {
      auth: {
        clientId,
      },
    };

    const myMsal = new PublicClientApplication(config);

    const loginRequest = {
      scopes: ["User.Read"],
    };

    myMsal.loginPopup(loginRequest).then(microsoftAuthCallback());
  }

  async function logout(clearAll = false) {
    StorageService.remove("token");

    if (clearAll) {
      StorageService.remove("login");
    }

    await userLogout();

    currentUser.value.access_level = "limited";
  }

  function getCurrentUser() {
    return $streemApiAdmin.get("me", { handleErrors }).then((response) => {
      currentUser.value = response.data;
    });
  }

  function getOrganisation(organisationId: number) {
    return Organisation.find(organisationId)
      .then((response) => {
        organisation.value = response.data;
      })
      .catch((error) => ErrorDialog("Failed to load organisation", error));
  }

  function setOrganisation(newOrganisation: Organisation): void {
    organisation.value = newOrganisation;
  }

  function unauthorized(error: unknown): void {
    loadError.value = error;
  }

  return {
    ...storeToRefs(sharedUserStore),
    loginFailed,
    loadError,
    accessLevels,
    adminRoles,
    organisation,

    hasAccessLevel,
    hasSuperAccess,
    hasNormalAccess,
    hasOrganisationEditAccess,
    hasOrganisationReadAccess,
    hasLimitedAccess,
    hasRosterAccess,
    hasMediaAnalystAccess,
    hasDERAccess,
    hasCopyrightAccess,
    isInsightsAMTeam,
    canManageOrganisation,

    setOrganisation,

    initGoogleAuth,
    initMicrosoftAuth,
    logout,
    unauthorized,
    getCurrentUser,
    getOrganisation,
  };
});

export default useUserStore;
