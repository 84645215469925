import {
  type AxiosRequestHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";

import { updateUserToken } from "shared/helpers/authorization";
import transformKeys from "shared/helpers/transformKeys";
import ensureTokenValidity from "shared/services/tokenService";

export async function refreshTokenResponseInterceptor(
  response: AxiosResponse
): Promise<AxiosResponse> {
  if (response.headers.authorization) {
    await updateUserToken(response.headers.authorization);
  }

  return response;
}

export function serializeJsonKeyInterceptor(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  if (config.transformKeys) {
    if (config.params) {
      Object.assign(config, {
        params: transformKeys(config.params, "snakeCase"),
      });
    }

    if (config.data) {
      Object.assign(config, {
        data: transformKeys(config.data, "snakeCase"),
      });
    }
  }

  return config;
}

export function deserializeJsonKeyInterceptor(
  response: AxiosResponse
): AxiosResponse {
  if (response.config.transformKeys) {
    response.data = transformKeys(response.data);
  }

  return response;
}

export async function authTokenRequestInterceptor(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const requestConfig = { ...config };

  requestConfig.handleErrors = config.handleErrors
    ? config.handleErrors
    : false;

  const headers = { ...config.headers } as AxiosRequestHeaders;

  if (!config.noAuth) {
    const token = await ensureTokenValidity();

    headers.Authorization = `Bearer ${token}`;
  }

  requestConfig.headers = headers;

  return requestConfig;
}
