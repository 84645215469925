import { type Simplify } from "shared/types";

type ReversedRecord<T extends Record<keyof T, string | number>> = {
  [P in T[keyof T]]: {
    [K in keyof T]: T[K] extends P ? K : never;
  }[keyof T];
};

// eslint-disable-next-line import/prefer-default-export
export const reverseRecord = <T extends Record<keyof T, string | number>>(
  obj: T
): Simplify<ReversedRecord<T>> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));
