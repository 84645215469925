import { POPOUT_PLAYER_TARGET } from "shared/constants";

export default {
  getPlayerOpenStyle: (state) =>
    state.playerOpen ? { marginBottom: "56px" } : {},
  isPopoutPoppingOut: (state) => {
    if (!state.playerInitialClip) return false;

    return state.playerPopoutTarget === POPOUT_PLAYER_TARGET;
  },
  isPlayerBeingScrubbed: (state) => state.playerIsBeingScrubbed,
};
