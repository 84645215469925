import { defineStore } from "pinia";
import { ref } from "vue";

import useTeamPicker from "shared/composables/useTeamPicker";
import { getCurrentTimestamp } from "shared/helpers/date";
import { TranscriptRequest } from "shared/resources";
import features from "shared/services/features";
import { useUserStore } from "shared/stores/user";

// eslint-disable-next-line import/prefer-default-export
export const useTranscriptRequestsStore = defineStore(
  "transcriptRequests",
  () => {
    const transcriptRequestsCount = ref(0);

    const fetchTranscriptRequestCount = async () => {
      try {
        const userStore = useUserStore();

        const response = await TranscriptRequest.where({
          completedAt: { gt: userStore.currentUser.transcripts_read_at },
          ...(features.has("has_team_workspaces")
            ? {
                organisationTeamId: [
                  useTeamPicker().selectedTeamPreference.value,
                  "null",
                ],
              }
            : {}),
        })
          .per(0)
          .page(1)
          .stats({ total: "count" })
          .all();

        transcriptRequestsCount.value = response.meta.stats.total.count;
      } catch {
        // do nothing
      }
    };

    const clearTranscriptRequestAlertsCount = async () => {
      transcriptRequestsCount.value = 0;
      const userStore = useUserStore();

      return userStore.updateUser({
        transcripts_read_at: getCurrentTimestamp(),
      });
    };

    return {
      transcriptRequestsCount,
      fetchTranscriptRequestCount,
      clearTranscriptRequestAlertsCount,
    };
  }
);
