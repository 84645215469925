import axios from "axios";
import { jwtDecode, type JwtPayload } from "jwt-decode";

import { identityBaseUrl } from "shared/constants";
import {
  updateUserRefreshToken,
  updateUserToken,
} from "shared/helpers/authorization";
import StorageService from "shared/services/StorageService";

async function refreshToken(): Promise<string | null> {
  try {
    const currentRefreshToken = await StorageService.get("refreshToken");

    const response = await axios.post(`${identityBaseUrl}/oauth/token`, {
      grant_type: "refresh_token",
      client_id: "identity-service",
      refresh_token: currentRefreshToken,
    });

    const newToken = response.data.access_token;
    const newRefreshToken = response.data.refresh_token;

    await updateUserToken(newToken);
    await updateUserRefreshToken(newRefreshToken);

    return newToken;
  } catch (_error) {
    return "";
  }
}

let isRefreshing = false;
let pendingRequests: Array<(token: string) => void> = [];

// Ensure only one request to the auth service is made at a time
async function handleTokenRefresh(): Promise<string | null> {
  if (!isRefreshing) {
    isRefreshing = true;

    try {
      const newToken = await refreshToken();

      // Resolve all pending requests with the new token
      pendingRequests.forEach((callback) => callback(newToken || ""));
      pendingRequests = [];

      isRefreshing = false;

      return newToken;
    } catch (error) {
      isRefreshing = false;
      // Resolve with an empty string if refresh token fails
      pendingRequests.forEach((callback) => callback(""));
      pendingRequests = [];
      throw error;
    }
  }

  // If already refreshing, wait for the new token
  return new Promise<string | null>((resolve) => {
    pendingRequests.push(resolve);
  });
}

// We are not following the standard
interface CustomJwtPayload extends JwtPayload {
  expires?: number;
}

function getTokenExpiration(token: string): number {
  const decoded: CustomJwtPayload = jwtDecode(token);

  return (decoded.expires || 0) * 1000; // exp is in seconds, convert to ms
}

export default async function ensureTokenValidity() {
  const token = (await StorageService.get("token")) as string;

  if (!token) {
    return "";
  }

  const expirationTime = getTokenExpiration(token);
  const currentTime = new Date().getTime();

  // Synchronously refresh the token if it's expired
  if (expirationTime - currentTime <= 0) {
    const newToken = await handleTokenRefresh();

    return newToken;
  }

  // Asychronously refresh the token if it's 60s from expiration
  if (expirationTime - currentTime < 60 * 1000) {
    handleTokenRefresh();
  }

  return token;
}
