<template>
  <div
    :class="[
      'tw-z-1 tw-min-w-[210px]',
      {
        '@tw-@container tw-absolute tw-inset-0 tw-h-full': !isPoppedOutVideo,
      },
    ]"
    @click="handleOverlayClick"
  >
    <div
      :class="[
        'tw-last:tw-flex-grow tw-last:tw-basis-full @lg:tw-item-end @lg:tw-flex-nowrap @lg:tw-gap-2 tw-flex tw-h-full tw-flex-wrap tw-items-end tw-justify-center tw-p-2 tw-transition-opacity tw-duration-300',
        {
          [supportsHoverClasses]: !isPoppedOutVideo,
        },
        touchControlClasses,
      ]"
      data-testid="overlay"
    >
      <BaseButton
        v-if="showPrevious"
        v-bind="buttonDefaults"
        :aria-label="
          getLocaleValue('player_popout_buttons.previous_item_in_queue')
        "
        :class="{ [unavailableClass]: isPreviousDisabled }"
        icon="ion-ios-skip-backward"
        :can-skip-previos="canSkipPrevious"
        @click="canSkipPrevious && $emit('skip-previous')"
      />
      <BaseButton
        v-bind="buttonDefaults"
        :aria-label="
          getLocaleValue('player_popout_buttons.thirty_seconds_back_aria')
        "
        :disable="firstPlay || disabled"
        icon="replay_30"
        @click="$emit('rewind')"
      />
      <BaseButton
        v-bind="buttonDefaults"
        :aria-label="getLocaleValue('player_popout_buttons.play_or_pause')"
        :disable="loading || disabled"
        :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
        @click="$emit('toggle-play')"
      />
      <BaseButton
        v-bind="buttonDefaults"
        :aria-label="
          getLocaleValue('player_popout_buttons.thirty_seconds_forward')
        "
        :disable="firstPlay || disabled"
        icon="forward_30"
        @click="$emit('fastforward')"
      />
      <BaseButton
        v-if="showNext"
        v-bind="buttonDefaults"
        :aria-label="getLocaleValue('player_popout_buttons.next_item_in_queue')"
        :class="{ [unavailableClass]: isNextDisabled }"
        icon="ion-ios-skip-forward"
        @click="canSkipNext && $emit('skip-next')"
      />
      <BaseButton
        v-if="showExpandButton"
        v-bind="buttonDefaults"
        :aria-label="getLocaleValue('player_popout_buttons.expand_player')"
        icon="ion-expand"
        show-expand-button
        @click="setPlayerExpanded(true)"
      />
      <BaseButton
        v-if="showShrinkButton"
        v-bind="buttonDefaults"
        :aria-label="getLocaleValue('player_popout_buttons.shrink_player')"
        icon="ion-contract"
        show-shrink-button
        @click="setPlayerExpanded(false)"
      />
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import type { PlayerControlProps } from "shared/components/players/types";

export interface PlayerPopoutVideoControlsProps extends PlayerControlProps {
  showNext?: boolean;
  showPrevious?: boolean;
}

type ButtonDefaults = {
  class?: string;
  color: string;
  flat: boolean;
  iconSize: "20px" | "34px";
  padding?: string;
};
</script>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, inject, ref, watch } from "vue";

import { getLocaleValue } from "shared/boot/i18n";
import { BaseButton } from "shared/components/base";
import { isTouchDevice as supportsTouch } from "shared/helpers/isTouchDevice";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";
import type { Nullable } from "shared/types";

const props = defineProps<PlayerPopoutVideoControlsProps>();

defineEmits([
  "fastforward",
  "rewind",
  "skip-next",
  "skip-previous",
  "toggle-play",
]);

const universalPlayerStore = useUniversalPlayerStore();
const { setPlayerExpanded } = universalPlayerStore;

const { playerIsExpanded, isPopoutPoppingOut, isPlayerBeingScrubbed } =
  storeToRefs(universalPlayerStore);

const isOverlayVisible = ref(false);

defineExpose({ isOverlayVisible });

const $isDesktop = inject("isDesktop");

const mobileOnlyTimerInterval =
  ref<Nullable<ReturnType<typeof setTimeout>>>(null);

const isTouchDevice = computed(() => supportsTouch());

const touchControlClasses = computed(() => {
  const hasControls = "tw-bg-black tw-bg-opacity-50 tw-opacity-100";
  const noControls = "tw-opacity-0";
  if (isPoppedOutVideo.value) return "";

  return isOverlayVisible.value ? hasControls : noControls;
});

const supportsHoverClasses = computed(() =>
  [
    "[@media(hover:hover)]:tw-bg-black",
    "[@media(hover:hover)]:tw-bg-opacity-50",
    "[@media(hover:hover)]:tw-opacity-0",
    "[@media(hover:hover)]:hover:tw-opacity-100",
    "[@media(hover:hover)]:active:tw-opacity-100",
  ].join(" ")
);

const buttonDefaults = computed<ButtonDefaults>(() => ({
  color: "white",
  flat: true,
  iconSize: isPopoutPoppingOut.value ? "20px" : "34px",
  class: "tw-p-2 @lg:tw-p-0",
}));

const isPreviousDisabled = computed(
  () =>
    props.noPreviousClip ||
    props.disabled ||
    !$isDesktop ||
    !isPopoutPoppingOut.value
);

const canSkipPrevious = computed(
  () =>
    !props.noPreviousClip &&
    !props.disabled &&
    $isDesktop &&
    isPopoutPoppingOut.value
);

const isNextDisabled = computed(
  () =>
    props.noNextClip ||
    props.disabled ||
    !$isDesktop ||
    !isPopoutPoppingOut.value
);

const canSkipNext = computed(
  () =>
    !props.noNextClip &&
    !props.disabled &&
    $isDesktop &&
    isPopoutPoppingOut.value
);

const showExpandButton = computed(
  () => !playerIsExpanded.value && isPoppedOutVideo.value
);

const showShrinkButton = computed(
  () => playerIsExpanded.value && isPoppedOutVideo.value
);

const isPoppedOutVideo = computed(() => isPopoutPoppingOut.value);

const unavailableClass = computed(() => "tw-cursor-default tw-opacity-30");

const startOverlayVisibilityTimer = () => {
  if (!isTouchDevice.value) return;
  isOverlayVisible.value = true;

  if (mobileOnlyTimerInterval.value) {
    clearTimeout(mobileOnlyTimerInterval.value);
  }

  mobileOnlyTimerInterval.value = setTimeout(() => {
    isOverlayVisible.value = false;
  }, 3000);
};

const handleOverlayClick = () => {
  startOverlayVisibilityTimer();
};

watch(isPlayerBeingScrubbed, (isBeingScrubbed) => {
  if (!isTouchDevice.value) return;

  if (isBeingScrubbed) {
    isOverlayVisible.value = true;

    if (mobileOnlyTimerInterval.value) {
      clearTimeout(mobileOnlyTimerInterval.value);
    }
  } else {
    startOverlayVisibilityTimer();
  }
});
</script>
