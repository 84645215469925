import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class SamlIntegration extends ApplicationRecord {
  static jsonapiType = "saml_integrations";

  @BelongsTo() organisation: Organisation;

  @Attr() idpEntityId: string;

  @Attr() idpCertificate: string;

  @Attr() idpUrl: string;

  @Attr({ persist: false }) scimToken: string;

  @Attr() customAttributes: Record<string, any>;

  @Attr() domains: Record<string, any>;
}

export default SamlIntegration;
