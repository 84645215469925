<template>
  <i
    class="s-icon"
    :class="iconClasses"
    aria-hidden="true"
    @click="$emit('click', $event)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  icon: String;
  iconClass?: String;
}>();

defineEmits(["click"]);

const iconClasses = computed(
  () => `s-icon-${props.icon}${props.iconClass ? ` ${props.iconClass}` : ""}`
);
</script>

<style lang="scss" scoped>
.s-icon {
  vertical-align: middle;
  line-height: 1;
  user-select: none;
  cursor: inherit;
}
</style>
