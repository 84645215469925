import { Attr, HasMany, HasOne, Model } from "spraypaint";

import SourceRecord from "shared/resources/SourceRecord";

import Category from "./Category";
import Outlet from "./Outlet";
import RadioStationsBroadcastRecorder from "./RadioStationsBroadcastRecorder";
import SourceGroup from "./SourceGroup";

@Model()
class RadioStation extends SourceRecord {
  static jsonapiType = "radio_stations";

  @HasOne() location: Location;

  @HasOne() sourceGroup: SourceGroup;

  @HasOne() category: Category;

  @HasOne() outlet: Outlet;

  @HasMany()
  radioStationsBroadcastRecorders: RadioStationsBroadcastRecorder[];

  @Attr() aliases: string[];

  @Attr() name: string;

  @Attr({ persist: false }) medium: string;

  @Attr() timeZone: string;

  @Attr() locationId: number;

  @Attr() logoUrl: string;

  @Attr() logoData: string;

  @Attr() categoryId: number;

  @Attr() gfkCode: string;

  @Attr() syndication: string;

  @Attr() languageCode: string;

  @Attr() streamUrl: string;

  @Attr() sourceGroupId: number;

  @Attr() transcriptionEngine: string;

  @Attr() visible: boolean;

  @Attr() tier: number;

  @Attr() advertisementRate: number;

  @Attr() label: string;

  @Attr() notes: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() notifyOutageViaPagerduty: boolean;

  @Attr() notifyOutageAfterMinutes: number;

  @Attr() ffmpegOptions: Record<string, any>;
}

export default RadioStation;
