import type { Nullable } from "shared/types";

type ISO8601Date = string;

export interface Token {
  token: string;
  userId: number;
}

export type AuthToken = Token | string;

export interface AccountManager {
  id: number;
  name: string;
  email: string;
  phone_number?: string;
  picture_url?: string;
}

export interface Preferences extends Record<string, any> {
  stream_section_expanded_managed_streams?: boolean;
  stream_section_expanded_mention_streams?: boolean;
  stream_section_expanded_shared_mention_streams?: boolean;
}

export interface OrganisationTeam {
  id: number;
  name: string;
  logo_url: string;
  brand_profile_id: number | null;
  primary: boolean;
  users_count: number;
  mention_streams_count?: number | null;
  social_streams_count?: number | null;
}

export interface OrganisationBrand {
  id: number;
  name: string;
  type: string;
  logo_url: string;
  theme_color: string;
  primary: boolean;
  header_text: string;
  header_image_url: string;
  font: string;
  custom_disclaimer: string | null;
  email_subject: string;
  organisation_team_id: number | null;
}

export interface PhoneCountryCode {
  label: string;
  phone_country_code: string;
  value: string;
}

export interface Organisation {
  id: number;
  name: string;
  region: string;
  logo_url: string;
  theme_color: string;
  dashboard_date_range: string;
  max_date_range: number;
  help_message_option: string;
  custom_help_message: string;
  service_level: string;
  aircall_option: string;
  pr_agency: boolean;
  global_customer_id: number | null;
  allowed_phone_country_codes: PhoneCountryCode[];
  custom_help_center_contact: string;
  help_center_contact_option: string;
  support_contact_option: string | null;
  custom_support_contact: string | null;
  social_help_center_contact_option: string;
  social_support_contact_option: string;
  features: string[];
  daily_report_range_offset: number;
  daily_report_range_duration: number;
  default_role_id: number;
  outreach_unsubscribed_recipients: string[];
  organisation_teams: OrganisationTeam[];
  organisation_brands: OrganisationBrand[];
  account_manager: AccountManager;
  secondary_account_managers: AccountManager[];
  active: boolean;
  stock_symbol: string | null;
}

export interface CurrentUserOrganisation {
  id: number;
  name: string;
  logo_url: string;
  dashboard_date_range: string;
  region: string;
}

export interface CurrentUserOrganisationBrand {
  name: string;
  logo_url: string;
  theme_color: string;
}

export interface CurrentUserOperationalTeam {
  name: string;
  id: number;
}

export const ACCESS_LEVELS = {
  super: ["super"],
  normal: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  organisation_edit: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  organisation_read: [
    "sales",
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  limited: [
    "limited",
    "super",
    "normal",
    "overnight_admin",
    "media_analyst",
    "content_admin",
  ],
  overnight_admin: ["super", "overnight_admin"],
  media_analyst: [
    "super",
    "media_analyst",
    "external_media_analyst",
    "content_admin",
  ],
  der: [
    "normal",
    "super",
    "overnight_admin",
    "media_analyst",
    "content_admin",
    "external_der_analyst",
  ],
  copyright_reporting: ["super", "content_admin", "copyright"],
  spelling_corrections: ["content_admin", "super"],
  content_removal: ["content_admin", "super"],
} as const;

export type AccessLevel = keyof typeof ACCESS_LEVELS;
export type AccessLevelsValues = (typeof ACCESS_LEVELS)[AccessLevel][number];

export interface CurrentUser {
  access_level: AccessLevelsValues;
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  phone_country_code: string | null;
  international_phone_number: string | null;
  time_zone: string;
  active: boolean;
  hidden: boolean;
  admin: boolean;
  welcome_sent_at: ISO8601Date | null;
  role: string;
  social_role: string;
  outreach_role: string;
  show_intercom: boolean;
  auto_refresh_streams: boolean;
  primary_organisation_team_id: number | null;
  secondary_team_ids: number[];
  two_factor_auth_carrier: string | null;
  two_factor_auth_enabled: boolean;
  enforce_two_factor: boolean;
  outreach_countries: string[];
  outreach_country: string[];
  permissions: string[];
  social_permissions: string[];
  outreach_permissions: string[];
  notifications_enabled: boolean;
  push_to_team_active: boolean;
  organisation: CurrentUserOrganisation;
  organisation_teams: OrganisationTeam[];
  organisation_brand: CurrentUserOrganisationBrand;
  alerts_read_at: ISO8601Date | null;
  news_summary_read_at: ISO8601Date | null;
  front_page_summary_read_at: ISO8601Date | null;
  media_requests_seen_at: ISO8601Date | null;
  transcripts_read_at: ISO8601Date | null;
  language: string | null;
  locale: string;
  currency_code: string | null;
  user_hash: string;
  preferences: Preferences;
  other_organisations: Omit<
    CurrentUserOrganisation,
    "dashboard_date_range" | "region"
  >[];
  operational_team?: CurrentUserOperationalTeam;
  organisation_team_id: Nullable<number>;
  launchpad_beta: boolean;
}

export interface TeamMember {
  id: number;
  first_name: string;
  last_name: string;
  organisation_teams: OrganisationTeam[];
  role: {
    id: number;
    name: string;
  };
}

export interface RootState {
  accountManager: AccountManager;
  betaEnabled: boolean;
  enforceTwoFactor: boolean;
  isLoggedIn: boolean;
  isAdminUser: boolean;
  loadError: null;
  pending: boolean;
  preferences: Preferences;
  token: string;
  identityProvider: string;
  currentUser: CurrentUser;
  showFullExcerpts: boolean;
  customerViewEnabled: boolean;
  organisation: Organisation;
  multiplier: Record<string, unknown>;
  team: TeamMember[];
}

export interface UpdateUser {
  alerts_read_at?: number;
  currency_code?: string;
  language?: string;
  phone_country_code?: string;
  phone_number?: string;
  time_zone?: string;
  transcripts_read_at?: number;
}

export type CurrentStreamsLayout = "list" | "stream";
