<template>
  <div
    class="player-popout-audio-controls tw-grid tw-h-12 tw-grid-rows-2 tw-items-center tw-overflow-hidden tw-rounded-xl tw-bg-denim-100 tw-pr-2"
  >
    <slot />
    <PlayerPopoutButtons
      :disabled="props.disabled"
      :first-play="props.firstPlay"
      :is-playing="props.isPlaying"
      :loading="props.loading"
      :no-next-clip="props.noNextClip"
      :no-previous-clip="props.noPreviousClip"
      @fastforward="emit('fastforward')"
      @rewind="emit('rewind')"
      @skip-next="emit('skip-next')"
      @skip-previous="emit('skip-previous')"
      @toggle-play="emit('toggle-play')"
    />
    <div class="formatted-time">
      <span
        class="tw-mr-2 tw-border-0 tw-border-r tw-border-solid tw-border-denim-300 tw-pr-2 tw-text-xs"
      >
        {{ localTime }}
      </span>
      <span class="tw-text-xs">
        {{ props.formattedTime }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import type { PlayerControlProps } from "shared/components/players/types";

export interface PlayerPopoutAudioControlsProps extends PlayerControlProps {
  currentTime?: number;
  formattedTime?: string;
}

export interface PlayerPopoutAudioControlsEmits {
  (e: "fastforward"): void;
  (e: "rewind"): void;
  (e: "skip-next"): void;
  (e: "skip-previous"): void;
  (e: "toggle-play"): void;
}

export const PLAYER_POPOUT_CONTROL_EVENTS = [
  "fastforward",
  "rewind",
  "skip-next",
  "skip-previous",
  "toggle-play",
] as const;
</script>

<script setup lang="ts">
import { computed } from "vue";

import { secondsToTimecodeWithSeconds } from "shared/helpers/date";

import PlayerPopoutButtons from "./PlayerPopoutButtons.vue";

const props = withDefaults(defineProps<PlayerPopoutAudioControlsProps>(), {
  formattedTime: "--:--:--",
  currentTime: 0,
});

const emit = defineEmits<PlayerPopoutAudioControlsEmits>();

const localTime = computed(() =>
  secondsToTimecodeWithSeconds(props.currentTime)
);
</script>

<style lang="scss" scoped>
.player-popout-audio-controls {
  --icon-size: 22px;
  --gap: 8px;

  grid-template-areas:
    "play-or-pause slider slider slider slider slider"
    "play-or-pause thirty-seconds-back gap thirty-seconds-forward flexible-space formatted-time";
  grid-template-columns:
    calc(var(--icon-size) * 2) // ⏯️
    var(--icon-size) // ⏪
    var(--gap)
    var(--icon-size) // ⏩
    1fr // flexible-space
    auto; // content specific width
}

.formatted-time {
  grid-area: formatted-time;
}
</style>
