/* eslint-disable no-param-reassign */
import { Notify } from "quasar";
import type { App } from "vue";

import DialogService, {
  type DialogServiceFunction,
} from "shared/modals/DialogService";
import { DialogTheme } from "shared/modals/types";
import type { Nullable } from "shared/types";

import { getLocaleText } from "./i18n";

let errorDialog: Nullable<ReturnType<DialogServiceFunction>> = null;
let isOpen = false;

export const AlertDialog = (message: string, timeout?: number) => {
  Notify.create({
    message,
    position: "center",
    timeout: timeout || Math.max(1000, message.length * 40),
    color: "primary",
  });
};

export const ToastDialog = (message: string, timeout?: number) => {
  Notify.create({
    message,
    timeout: timeout || Math.max(1000, message.length * 40),
    color: "ds-denim-9",
  });
};

export const ErrorDialog = (title: string, error: any) => {
  if (errorDialog && isOpen) {
    errorDialog.hide();
  }

  let message: string | undefined;
  let dialogTitle: string;

  if (error?.response?.status === 401) {
    dialogTitle = getLocaleText("boot_alert.dialog_title");
  } else {
    dialogTitle = title;

    message =
      error?.response?.data?.message ||
      error?.response?.data?.errors?.[0]?.detail ||
      error?.response?.data?.errors?.[0]?.title ||
      error.message;
  }

  errorDialog = DialogService.alert({
    theme: DialogTheme.DANGER,
    title: dialogTitle,
    message,
    confirmLabel: getLocaleText("global.close"),
    confirmButtonProps: { class: "" },
  })
    .onOk(() => {
      isOpen = false;
    })
    .onCancel(() => {
      isOpen = false;
    });

  isOpen = true;
};

export default ({ app }: { app: App }) => {
  app.config.globalProperties.$alert = AlertDialog;
  app.config.globalProperties.$toast = ToastDialog;
  app.config.globalProperties.$error = ErrorDialog;
};
